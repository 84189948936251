import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { getUsers } from "../../actions/userAction";
import { roles, labelRoles } from "../../variables/roles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { restoreUser } from "../../actions/userAction";

import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import UserTable from "components/ReactTable/ReactTable";

import { convertToDateFromMS } from "../../variables/time";
import moment from 'moment';

const useStyles = makeStyles({ ...tableStyles, ...alertStyles });

export default function ArchivedUsers() {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.users);
  const history = useHistory();
  const totalPages = useSelector((state) => state.users.totalPagesDeleted);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [numberOfRows, setNumberOfRows] = React.useState(20);
  const [sortBy, setSortBy] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [approvingUser, setApprovingUser] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if (usersData && usersData.data && usersData.data.length) return;
    dispatch(getUsers(0, 20, "companies"));
  }, []);

  const onFetchData = (page, size, sortBy, filters) => {
    const updatedSortBy = sortBy.map(sort => {
      if (sort.id === 'archivedAt') {
        return { ...sort, id: 'archivedAt' };
      }
      return sort;
    });
  
    const updatedFilters = filters.map(filter => {
      if (filter.id === 'archivedAt') {
        return { ...filter, id: 'archivedAt' };
      }
      return filter;
    });
  
    dispatch(getUsers(page, size, "companies", updatedSortBy, updatedFilters));
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "employer.companyName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Archived Date",
        accessor: "archivedAt",
        sortType: "datetime",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const roundButtons = ({ userID, role, active }) => {
    let btnArr;
    if (active) {
      btnArr = [{ type: "edit", color: "success", icon: Edit }];
    } else {
      btnArr = [{ type: "edit", color: "success", icon: Edit }];
    }
    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
          disabled={approvingUser === userID}
          onClick={() => {
            setApprovingUser(userID);
            dispatch(
              restoreUser({
                userID,
                payload: {
                  active: true,
                },
                callback: () => {
                  setApprovingUser(null);
                },
              })
            );
          }}
        >
          Restore User
        </Button>
      );
    });
  };

  const renderTable = React.useMemo(() => {
    const users = usersData.deletedUsers;

    if (!users || !users.length) {
      return <>No Data Found!</>;
    }

    const filteredUsers = users.filter(user => user.role === roles.employer).map((user, id) => ({
      ...user,
      id: (id + 1) + pageIndex * numberOfRows,
      name: `${user.firstName} ${user.lastName}`,
      archivedAt: moment(user.archivedAt).format('DD.MM.YYYY'),
      actions: roundButtons({
        userID: user._id,
        role: user.role,
        active: user.active,
      }),
    }));

    return (
      <UserTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        numberOfRows={numberOfRows}
        setNumberOfRows={setNumberOfRows}
        initSortBy={sortBy}
        setSortBy={setSortBy}
        initFilters={filters}
        setFilters={setFilters}
        columns={columns}
        data={filteredUsers}
        onFetchData={onFetchData}
        totalPages={totalPages}
      />
    );
  }, [usersData]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div className="go-back">
          <span onClick={(e) => history.push("/admin/companies")}>
            <ArrowBackIosIcon />
            <span className="text-in">Go Back</span>
          </span>
        </div>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <div className="d-flex justify-content-between align-items-center ">
              <h4 className={classes.cardIconTitle}>Archived Company Users</h4>
            </div>
          </CardHeader>
          <CardBody>
            {usersData.loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>{renderTable}</>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
