import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import { DECIMAL_NUMBER, NUMBER_ONLY } from "../../variables/validate";
import Assignment from "@material-ui/icons/Assignment";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useFormik} from "formik";
import * as Yup from "yup";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import {getSettings, updateSettings} from "../../actions/settingsAction";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function ExtendedTables() {
  const dispatch = useDispatch();
  const settingsData = useSelector((state) => state.settings);
  const {data} = settingsData;
  const settings = {...data};

  const classes = useStyles();

  useEffect(() => {
    if (settingsData && settingsData.length) return;
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (String(settings.footerLogo).startsWith('public/')) {
        formik.setFieldValue('footerLogo', settings.footerLogo);
      }
      if (String(settings.logo).startsWith('public/')) {
        formik.setFieldValue('logo', settings.logo);
      }
    }, 200);
  }, [settingsData]);

  const formik = useFormik({
    initialValues: {
      logo: settings.logo || '',
      email: settings.email || '',
      phone: settings.phone || '',
      address: settings.address || '',
      eventFee: settings.eventFee || '',
      staffTypes: settings.staffTypes || [],
      footerLogo: settings.footerLogo || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid E-Mail Address"),
      phone: Yup.string().required("A phone number is required"),
      address: Yup.string(),
      eventFee: Yup.string('Event fee must be a number').matches(DECIMAL_NUMBER, 'Event fee must be a number').required('Event fee is required'),
    }),
    onSubmit: async (values) => {
      let formData = new FormData();
      Object.entries({
        ...values,
        staffTypes: JSON.stringify(values.staffTypes),
      }).map((value) => {
        formData.append(value[0], value[1]);
      });
      values.staffTypes?.forEach((staff, index) => {
        ['contentImage', 'sitebarImage'].forEach((image) => {
          if (staff[image] instanceof File) {
            formData.append(`staffType_${image}_${index}`, staff[image]);
          }
        });
      });
      dispatch(
        updateSettings({
          id: settings._id,
          payload: formData,
        })
      );
    },
  });

  if (settingsData.loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "35px 15px",
        }}
      >
        <CircularProgress/>
      </div>
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Settings</h4>
          </CardHeader>
          <CardBody>
            <form
              onSubmit={formik.handleSubmit}
              className="form form-first-registration"
            >
              <GridContainer>
                <GridItem xs={6} md={6} lg={6}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                      name: "email",
                      value: formik.values.email,
                      onChange: formik.handleChange,
                      touched: formik.touched.email,
                      errors: formik.errors.email,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} md={6} lg={6}>
                  <CustomInput
                    labelText="Phone"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "phone",
                      value: formik.values.phone,
                      onChange: formik.handleChange,
                      touched: formik.touched.phone,
                      errors: formik.errors.phone,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Address"
                    id="address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "address",
                      value: formik.values.address,
                      onChange: formik.handleChange,
                      touched: formik.touched.address,
                      errors: formik.errors.address,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={12} lg={12}>
                  <div className="company-logo-in">
                    <label>Site Logo</label>
                    <ImageUpload
                      file={formik.values.logo}
                      setFile={(file) => formik.setFieldValue("logo", file)}
                      avatar={formik.values.logo}
                      addButtonProps={{
                        color: "rose",
                        round: true,
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true,
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true,
                      }}
                      inputProps={{
                        name: "logo",
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} md={12} lg={12}>
                  <div className="company-logo-in">
                    <label>Site Footer Logo</label>
                    <ImageUpload
                      file={formik.values.footerLogo}
                      setFile={(file) =>
                        formik.setFieldValue("footerLogo", file)
                      }
                      avatar={formik.values.footerLogo}
                      addButtonProps={{
                        color: "rose",
                        round: true,
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true,
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true,
                      }}
                      inputProps={{
                        name: "footerLogo",
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={6} md={6} lg={6}>
                  <CustomInput
                    labelText="Event Fee (excl. VAT)"
                    id="eventFee"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "float",
                      name: "eventFee",
                      value: formik.values.eventFee,
                      touched: formik.touched.eventFee,
                      errors: formik.errors.eventFee,
                      onBlur: formik.handleBlur,
                      onChange: (e) => {
                        if (
                          e.target.value === "" ||
                          NUMBER_ONLY.test(e.target.value)
                        ) {
                          formik.handleChange(e);
                        }
                      }
                    }}
                  />
                </GridItem>
                {formik.values.staffTypes ? (
                  <GridItem xs={12} md={12} lg={12}>
                    <h5>Staff Types</h5>
                    {formik.values.staffTypes.map((link, typeid) => {
                      return (
                        <GridContainer key={typeid}>
                          <GridItem xs={1} md={1} lg={1}>
                            <h6
                              style={{
                                width: "35px",
                                height: "35px",
                                backgroundColor: "#ec407a",
                                color: "#fff",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {typeid + 1}
                            </h6>
                          </GridItem>
                          <GridItem xs={3} md={3} lg={3}>
                            <CustomInput
                              labelText="Name"
                              id="name"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "name",
                                value: formik.values.staffTypes[typeid].name,
                                onChange: (e) => {
                                  const newValues = formik.values.staffTypes.map(
                                    (value, vid) => {
                                      if (vid === typeid) {
                                        return {
                                          ...value,
                                          name: e.target.value,
                                        };
                                      } else {
                                        return {...value};
                                      }
                                    }
                                  );
                                  formik.setFieldValue("staffTypes", newValues);
                                },
                                onBlur: formik.handleBlur,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} lg={3}>
                            <CustomInput
                              labelText="Price Per Hour (in euro)"
                              id="pricePerHour"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "pricePerHour",
                                value:
                                formik.values.staffTypes[typeid].pricePerHour,
                                onChange: (e) => {
                                  if (
                                    e.target.value === "" ||
                                    NUMBER_ONLY.test(e.target.value)
                                  ) {
                                    const newValues = formik.values.staffTypes.map(
                                      (value, vid) => {
                                        if (vid === typeid) {
                                          return {
                                            ...value,
                                            pricePerHour: e.target.value,
                                          };
                                        } else {
                                          return {...value};
                                        }
                                      }
                                    );
                                    formik.setFieldValue(
                                      "staffTypes",
                                      newValues
                                    );
                                  }
                                },
                                onBlur: formik.handleBlur,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} lg={3}>
                            <CustomInput
                              labelText="Effective Price Per Hour (in euro)"
                              id="effectivePricePerHour"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "effectivePricePerHour",
                                value:
                                  formik?.values.staffTypes[typeid]
                                    .effectivePricePerHour ?? "",
                                onChange: (e) => {
                                  if (
                                    e.target.value === "" ||
                                    NUMBER_ONLY.test(e.target.value)
                                  ) {
                                    const newValues = formik.values.staffTypes.map(
                                      (value, vid) => {
                                        if (vid === typeid) {
                                          return {
                                            ...value,
                                            effectivePricePerHour:
                                            e.target.value,
                                          };
                                        } else {
                                          return {...value};
                                        }
                                      }
                                    );
                                    formik.setFieldValue(
                                      "staffTypes",
                                      newValues
                                    );
                                  }
                                },
                                onBlur: formik.handleBlur,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={1} md={1} lg={1}>
                            <div className="delete-wrapper">
                              <Delete
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="deleteBtn"
                                onClick={(e) => {
                                  const newValues = formik.values.staffTypes.filter(
                                    (flink, fid) => fid !== typeid
                                  );
                                  formik.setFieldValue("staffTypes", newValues);
                                }}
                              />
                            </div>
                          </GridItem>
                          <GridItem xs={1} md={1} lg={1}></GridItem>
                          <GridItem xs={1} md={1} lg={1}></GridItem>
                          <GridItem xs={5} md={5} lg={5}>
                            <div>Content image</div>
                            <ImageUpload
                              file={formik?.values.staffTypes[typeid]?.contentImage}
                              setFile={(file) => {
                                const staff = formik.values.staffTypes;
                                staff[typeid].contentImage = file;
                                formik.setFieldValue(
                                  "staffTypes",
                                  staff,
                                );
                              }}
                              avatar={formik?.values.staffTypes[typeid]?.contentImage}
                              addButtonProps={{
                                color: "rose",
                                round: true,
                              }}
                              changeButtonProps={{
                                color: "rose",
                                round: true,
                              }}
                              removeButtonProps={{
                                color: "danger",
                                round: true,
                              }}
                              inputProps={{
                                name: "logo",
                              }}
                            />
                          </GridItem>
                          <GridItem xs={5} md={5} lg={5}>
                            <div>Sitebar image</div>
                            <ImageUpload
                              file={formik?.values.staffTypes[typeid]?.sitebarImage}
                              setFile={(file) => {
                                const staff = formik.values.staffTypes;
                                staff[typeid].sitebarImage = file;
                                formik.setFieldValue(
                                  "staffTypes",
                                  staff,
                                );
                              }}
                              avatar={formik?.values.staffTypes[typeid]?.sitebarImage}
                              addButtonProps={{
                                color: "rose",
                                round: true,
                              }}
                              changeButtonProps={{
                                color: "rose",
                                round: true,
                              }}
                              removeButtonProps={{
                                color: "danger",
                                round: true,
                              }}
                              inputProps={{
                                name: "logo",
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                    <Button
                      onClick={(e) =>
                        formik.setFieldValue("staffTypes", [
                          ...formik.values.staffTypes,
                          {
                            name: "",
                            pricePerHour: "",
                            effectivePricePerHour: "",
                          },
                        ])
                      }
                    >
                      Add Staff Type
                    </Button>
                  </GridItem>
                ) : (
                  <></>
                )}
                <GridItem xs={12} md={12} lg={12}>
                  <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={formik.isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
