import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js';
import PropTypes from 'prop-types';
import RejectedApplicantTable from './RejectedApplicantTable';

const useStyles = makeStyles(styles);

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

function RejectedApplicationsModal({ classicModal, setClassicModal }) {
  const classes = useStyles();
  const [tableData, setTableData] = React.useState([]);
  const [pageIndex, setPageIndex] = React.useState(0);

  React.useEffect(() => {
    let rApplicants = classicModal?.position?.rejectedApplicants || [];
    let applicants = classicModal?.position?.applications || [];

    const mapApplicantsWithStatus = (applicants, status) =>
      applicants.map((application) => ({
        name: application?.applicant?.name,
        email: application?.applicant?.email,
        phone: application?.applicant?.phone,
        status,
      }));

    const combinedApplicants = [
      ...mapApplicantsWithStatus(rApplicants, 'rejected applicant'),
      ...mapApplicantsWithStatus(applicants, ''),
    ];

    setTableData(combinedApplicants);
  }, [
    classicModal?.position?.rejectedApplicants,
    classicModal?.position?.applications,
  ]);

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot,
        paper: classes.modal,
      }}
      open={classicModal.isVisible}
      keepMounted
      PaperProps={{
        style: {
          maxWidth: '700px',
          width: '100%',
        },
      }}
      onClose={() => setClassicModal({ ...classicModal, isVisible: false })}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setClassicModal({ ...classicModal, isVisible: false })}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Applications & Rejected Applications</h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        {tableData.length > 0 ? (
          <RejectedApplicantTable
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            columns={columns}
            data={tableData}
            showPagination={false}
          />
        ) : (
          <>No Data Found!</>
        )}
      </DialogContent>
    </Dialog>
  );
}

RejectedApplicationsModal.propTypes = {
  classicModal: PropTypes.any,
  setClassicModal: PropTypes.func,
};

export default RejectedApplicationsModal;
