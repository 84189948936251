import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import styles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PropTypes from 'prop-types';
import React from 'react';
import api, { apiv2 } from '../../variables/api';
import { getLanguageName } from 'variables/utils';
import CircularProgress from '@material-ui/core/CircularProgress';

const calculateAge = (dob) => {
  var month_diff = Date.now() - dob;

  //convert the calculated difference in date format
  var age_dt = new Date(month_diff);

  //extract year from date
  var year = age_dt.getUTCFullYear();

  //now calculate the age of the user
  var age = Math.abs(year - 1970);
  return age;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const textStyles = {
  typo: {
    // paddingLeft: "32%",
    marginBottom: '25px',
    position: 'relative',
    display: 'flex',
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: '10px',
    color: '#c0c1c2',
    display: 'block',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '13px',
    left: '0',
    marginLeft: '20px',
    // position: "absolute",
    width: '150px',
  },
  heading: {
    marginTop: 0,
  },
};

const useStyles = makeStyles({ ...styles, ...textStyles });

const getMultipleLanguagesName = (languages) => {
  const langs = languages.map((lang) => {
    const temp = getLanguageName(lang);
    return temp;
  });
  return langs.toString();
};

function ViewStaffDetails({
  allowApprove,
  approvingUser,
  handleApproveUser,
  classicModal,
  setClassicModal,
}) {
  const classes = useStyles();
  const user = classicModal?.user;
  const [employee, setEmployee] = useState(null);
  const [employer, setEmployer] = useState(null);
  const [imgLoading, setImgLoading] = React.useState(true);
  const [userImg, setUserImg] = React.useState([]);

  useEffect(() => {
    apiv2
      .get(`/${user.role === 'employee' ? 'user' : 'company'}?id=${user._id}`)
      .then((res) => {
        if (user.role === 'employee') {
          setEmployee(res.data.data.user?.employee);
        } else {
          let employer = res.data.data.user?.employer;
          employer.photo = res.data.data.user?.photo;
          setEmployer(employer);
        }
      })
      .catch((err) => console.error(err));
  }, [user]);

  useEffect(() => {
    if (employee && employee.images) {
      const fetchImages = async () => {
        const images = await Promise.all(
          employee.images.map(async (image) => {
            const img = await api.post('users/getImageUrl', { image });
            return img;
          })
        );
        setUserImg(images);
        setImgLoading(false);
      };
      fetchImages();
    } else if (employer) {
      const fetchImage = async () => {
        const img = await api.post('users/getImageUrl', {
          image: employer.photo,
        });
        setUserImg(img.data.image);
        setImgLoading(false);
      };
      fetchImage();
    }
  }, [employee, employer]);

  const getBooleanValues = (val) => {
    if (val === true || val === false) {
      return val ? 'YES' : 'NO';
    } else {
      return '-';
    }
  };

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot,
        paper: classes.modal,
      }}
      open={classicModal.isVisible}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        style: {
          maxWidth: '1280px',
          width: '100%',
        },
      }}
      onClose={() => setClassicModal({ ...classicModal, isVisible: false })}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setClassicModal({ ...classicModal, isVisible: false })}
        >
          <Close className={classes.modalClose} />
        </Button>

        <h4 className={classes.modalTitle}>
          {user?.role === 'employee' ? 'Staff' : 'Company'} Details
        </h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        {user?.role === 'employee' && (
          <GridContainer>
            <GridItem xs={3}>
              <div className="employee-img-wrapper">
                <label>Staff Profile Images</label>
                {employee?.images && employee?.images.length ? (
                  <>
                    <GridContainer>
                      {imgLoading ? (
                        <CircularProgress />
                      ) : (
                        userImg.map((i, index) => (
                          <GridItem xs={6} md={6} lg={6} key={index}>
                            <div className="img-in">
                              <img
                                key={index}
                                src={
                                  userImg
                                    ? i?.data
                                      ? i.data.image
                                      : URL.createObjectURL(i)
                                    : ''
                                }
                                alt="Profile"
                                className="img-in"
                              />
                            </div>
                          </GridItem>
                        ))
                      )}
                    </GridContainer>
                  </>
                ) : (
                  <>
                    <div>No Profile Images Found!</div>
                  </>
                )}
              </div>
            </GridItem>
            <GridItem xs={4}>
              <GridContainer>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Name</div>
                    <h5 className={classes.heading}>
                      {user?.firstName && user?.firstName}{' '}
                      {user?.lastName && user?.lastName}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Email</div>
                    <h5 className={classes.heading}>
                      {user?.email ? user?.email : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Phone</div>
                    <h5 className={classes.heading}>
                      {user?.phone ? user?.phone : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Gender</div>
                    <h5 className={classes.heading}>
                      {employee?.gender ? employee?.gender : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Age</div>
                    <h5 className={classes.heading}>
                      {employee?.dateOfBirth
                        ? calculateAge(employee?.dateOfBirth)
                        : '-'}
                    </h5>
                  </div>
                </GridItem>

                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Nationality</div>
                    <h5 className={classes.heading}>
                      {employee?.nationality ? employee?.nationality : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Weight</div>
                    <h5 className={classes.heading}>
                      {employee?.weight ? employee?.weight : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Hair</div>
                    <h5 className={classes.heading}>
                      {employee?.hairColor ? employee?.hairColor : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Size</div>
                    <h5 className={classes.heading}>
                      {employee?.size ? employee?.size : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Shoe Size</div>
                    <h5 className={classes.heading}>
                      {employee?.shoeSize ? employee?.shoeSize : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Issued to</div>
                    <h5 className={classes.heading}>
                      {employee?.issuedName ? employee?.issuedName : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>IBAN</div>
                    <h5 className={classes.heading}>
                      {employee?.iban ? employee?.iban : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Height</div>
                    <h5 className={classes.heading}>
                      {employee?.height ? employee?.height : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Country</div>
                    <h5 className={classes.heading}>
                      {employee?.country ? employee?.country : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>City</div>
                    <h5 className={classes.heading}>
                      {employee?.city ? employee?.city : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>BlackPantsuit</div>
                    <h5 className={classes.heading}>
                      {getBooleanValues(employee?.blackPantsuit)}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>preferedJobRegion</div>
                    <h5 className={classes.heading}>
                      {employee?.preferedJobRegion?.length
                        ? employee?.preferedJobRegion.toString()
                        : '-'}
                    </h5>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={5}>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>BlackSkirtBlazer</div>
                  <h5 className={classes.heading}>
                    {getBooleanValues(employee?.blackSkirtBlazer)}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>AddressLine1</div>
                  <h5 className={classes.heading}>
                    {employee?.addressLineOne ? employee?.addressLineOne : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Size</div>
                  <h5 className={classes.heading}>
                    {employee?.size ? employee?.size : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>PostalCode</div>
                  <h5 className={classes.heading}>
                    {employee?.postalCode ? employee?.postalCode : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Mother Tongue</div>
                  <h5 className={classes.heading}>
                    {employee?.motherTongue
                      ? getLanguageName(employee?.motherTongue)
                      : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Foreign Languages</div>
                  <h5 className={classes.heading}>
                    {employee?.foreignLanguages &&
                    employee?.foreignLanguages.length
                      ? getMultipleLanguagesName(employee?.foreignLanguages)
                      : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Previous Jobs</div>
                  <h5 className={classes.heading}>
                    {employee?.previousJobs && employee?.previousJobs.length
                      ? employee?.previousJobs.toString()
                      : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Prefered Jobs</div>
                  <h5 className={classes.heading}>
                    {employee?.preferedJobs && employee?.preferedJobs.length
                      ? employee?.preferedJobs.toString()
                      : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Piercings</div>
                  <h5 className={classes.heading}>
                    {getBooleanValues(employee?.piercings)}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>{`Driver's License`}</div>
                  <h5 className={classes.heading}>
                    {getBooleanValues(employee?.driversLicense)}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Car Available</div>
                  <h5 className={classes.heading}>
                    {getBooleanValues(employee?.carAvailable)}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Visible Tattoos</div>
                  <h5 className={classes.heading}>
                    {getBooleanValues(employee?.visibleTattoos)}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Job Experience</div>
                  <h5 className={classes.heading}>
                    {employee?.jobExperience ? employee?.jobExperience : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Social Security Number</div>
                  <h5 className={classes.heading}>
                    {employee?.ssn ? employee?.ssn : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Bank name</div>
                  <h5 className={classes.heading}>
                    {employee?.nameOfInstitute
                      ? employee?.nameOfInstitute
                      : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>BIC</div>
                  <h5 className={classes.heading}>
                    {employee?.bic ? employee?.bic : '-'}
                  </h5>
                </div>
              </GridItem>
            </GridItem>
          </GridContainer>
        )}
        {(user?.role === 'employer' || user?.role === 'superuser') && (
          <GridContainer>
            <GridItem xs={3}>
              <div className="img-in">
                {imgLoading ? (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : userImg && userImg.length ? (
                  <img src={userImg} alt="Profile" />
                ) : (
                  <h5 className={classes.heading}>No Images Found!</h5>
                )}
              </div>
            </GridItem>
            <GridItem xs={4}>
              <GridContainer>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Company name</div>
                    <h5 className={classes.heading}>
                      {user?.employer?.companyName
                        ? user?.employer?.companyName
                        : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Name</div>
                    <h5 className={classes.heading}>
                      {user?.firstName && user?.firstName}{' '}
                      {user?.lastName && user?.lastName}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Email</div>
                    <h5 className={classes.heading}>
                      {user.email ? user?.email : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Phone</div>
                    <h5 className={classes.heading}>
                      {user.phone ? user?.phone : '-'}
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div className={classes.note}>Contact Person</div>
                    <h5 className={classes.heading}>
                      {employer?.contactPerson ? employer?.contactPerson : '-'}
                    </h5>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={5}>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Address</div>
                  <h5 className={classes.heading}>
                    {employer?.addressLineOne && employer?.addressLineOne}{' '}
                    {employer?.addressLineTwo && employer?.addressLineTwo}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>City</div>
                  <h5 className={classes.heading}>
                    {employer?.city ? employer?.city : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Country</div>
                  <h5 className={classes.heading}>
                    {employer?.country ? employer?.country : '-'}
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.typo}>
                  <div className={classes.note}>Postal Code</div>
                  <h5 className={classes.heading}>
                    {employer?.postalCode ? employer?.postalCode : '-'}
                  </h5>
                </div>
              </GridItem>
            </GridItem>
          </GridContainer>
        )}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        {allowApprove && (
          <Button
            disabled={approvingUser}
            Button
            variant="contained"
            color="primary"
            onClick={() => handleApproveUser(user._id)}
          >
            Approve User
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ViewStaffDetails.propTypes = {
  // user: PropTypes.object,
  approvingUser: PropTypes.array,
  handleApproveUser: PropTypes.func,
  classicModal: PropTypes.object,
  setClassicModal: PropTypes.func,
  allowApprove: PropTypes.bool,
};

export default ViewStaffDetails;
