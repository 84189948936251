import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// material-ui icons
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import { getUnapprovedUsers, approveUser } from '../../actions/userAction';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import ViewStaffDetails from './ViewStaffDetails';
import alertStyles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import UserTable from 'components/ReactTable/ReactTable';
import UpdateModal from './UpdateModal';

const useStyles = makeStyles({ ...tableStyles, ...alertStyles });

export default function ApproveUsers() {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.users);
  const history = useHistory();
  const classes = useStyles();
  const [approvingUser, setApprovingUser] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(20);
  const [sortBy, setSortBy] = useState([]);
  const [filters, setFilters] = useState([]);
  const [staffModal, setStaffModal] = useState(false);
  const [classicModal, setClassicModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(getUnapprovedUsers()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const onFetchData = (page, size, newSortBy, newFilters) => {
    setPageIndex(page);
    setNumberOfRows(size);
    setSortBy(newSortBy);
    setFilters(newFilters);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
      },
    ],
    []
  );

  const handleApproveUser = (userID) => {
    setApprovingUser(userID);
    dispatch(
      approveUser({
        userID: userID,
        callback: () => {
          setApprovingUser(null);
          setStaffModal(false);
        },
      })
    );
  };

  const roundButtons = ({ userID, user }) => {
    let btnArr = [
      { type: 'view', color: 'primary', icon: VisibilityIcon },
      { type: 'edit', color: 'info', icon: Edit },
      { type: 'approve', color: 'success', icon: Edit },
    ];
    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + ' ' + classes.actionButtonRound}
          key={key}
          disabled={approvingUser === userID}
          onClick={() => {
            if (prop.type === 'view') {
              setStaffModal({
                isVisible: true,
                user,
              });
            } else if (prop.type === 'edit') {
              setClassicModal({
                isVisible: true,
                user,
              });
            } else {
              handleApproveUser(userID);
            }
          }}
        >
          {prop.type === 'view' ? (
            <VisibilityIcon />
          ) : prop.type === 'edit' ? (
            <Edit />
          ) : (
            'Approve User'
          )}
        </Button>
      );
    });
  };

  const renderTable = useMemo(() => {
    let users = usersData.unapprovedUsers || [];

    if (sortBy.length) {
      const { id, desc } = sortBy[0];
      users = [...users].sort((a, b) => {
        if (id === 'name') {
          const nameA = a.lastName.toLowerCase();
          const nameB = b.lastName.toLowerCase();
          if (nameA < nameB) return desc ? 1 : -1;
          if (nameA > nameB) return desc ? -1 : 1;
          return 0;
        } else {
          if (a[id] < b[id]) return desc ? 1 : -1;
          if (a[id] > b[id]) return desc ? -1 : 1;
          return 0;
        }
      });
    }

    const startIndex = pageIndex * numberOfRows;
    const endIndex = startIndex + numberOfRows;
    const paginatedUsers = users.slice(startIndex, endIndex);

    const filteredUsers = paginatedUsers.map((user, id) => ({
      ...user,
      id: startIndex + id + 1,
      name: `${user.firstName} ${user.lastName}`,
      actions: roundButtons({
        userID: user._id,
        user,
      }),
    }));

    return (
      <UserTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        numberOfRows={numberOfRows}
        setNumberOfRows={setNumberOfRows}
        initSortBy={sortBy}
        setSortBy={setSortBy}
        initFilters={filters}
        setFilters={setFilters}
        columns={columns}
        data={filteredUsers}
        onFetchData={onFetchData}
        totalPages={Math.ceil(users.length / numberOfRows)}
      />
    );
  }, [usersData, numberOfRows, pageIndex, sortBy, filters]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div className="go-back">
          <span onClick={() => history.push('/admin/users')}>
            <ArrowBackIosIcon />
            <span className="text-in">Go Back</span>
          </span>
        </div>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <div className="d-flex justify-content-between align-items-center ">
              <h4 className={classes.cardIconTitle}>Approve Staff</h4>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '35px 15px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>{renderTable}</>
            )}
            {staffModal.isVisible && (
              <ViewStaffDetails
                approvingUser={approvingUser}
                handleApproveUser={handleApproveUser}
                classicModal={staffModal}
                setClassicModal={setStaffModal}
                allowApprove={true}
              />
            )}
            {classicModal.isVisible && (
              <UpdateModal
                users={usersData.data}
                classicModal={classicModal}
                setClassicModal={setClassicModal}
                setLoading={setLoading}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
