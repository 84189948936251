import React from "react";
import PropTypes from "prop-types";
import EventTable from "components/ReactTable/LegacyReactTable";
import moment from "moment";

const JobRow = (props) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "LOCATION",
        accessor: "location",
      },
      {
        Header: "DATE",
        accessor: "date",
      },
      {
        Header: "EMPLOYEE",
        accessor: "employee",
      },
      {
        Header: "START",
        accessor: "start",
      },
      {
        Header: "END",
        accessor: "end",
      },
      {
        Header: "EMAIL",
        accessor: "email",
      },
      {
        Header: "TELEPHONE",
        accessor: "telephone",
      },
      {
        Header: "STATUS",
        accessor: "status",
      },
    ],
    []
  );

  const renderBookedJobPositions = (job) => {
    const filteredjobPositions = [];
    job.jobPositions.map((jobPosition, key) => {
      if (jobPosition?.booked && jobPosition?.booked?.employee) {
        filteredjobPositions?.push({
          location: jobPosition.jobPlace,
          date: moment(jobPosition.jobDate).format("dddd, DD.MM.YY") ?? "",
          employee: jobPosition.booked.employee.name,
          // start:  moment(jobPosition.jobStart).format('HH:mm') ?? '',
          // end:  moment(jobPosition.jobEnd).format('HH:mm') ?? '',
          start: jobPosition.confirmJobStart
            ? moment(parseInt(jobPosition.confirmJobStart)).format("HH:mm")
            : moment(jobPosition.jobStart).format("HH:mm"),
          end: jobPosition.confirmJobEnd
            ? moment(parseInt(jobPosition.confirmJobEnd)).format("HH:mm")
            : moment(jobPosition.jobEnd).format("HH:mm"),
          email: jobPosition.booked.employee.email,
          telephone: jobPosition.booked.employee.phone,
          status: (
            <span
              className={`${
                jobPosition?.contractSubmited && jobPosition?.signedContract
                  ? "eventStatusGreen"
                  : "eventStatusRed"
              }`}
            ></span>
          ),
        });
      }
      return jobPosition;
    });
    if (filteredjobPositions?.length > 0) {
      return (
        <>
          <h3 className="headline-with-line">{props.job.jobName}</h3>
          <EventTable
            columns={columns}
            data={filteredjobPositions}
            showPagination={false}
          />
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <div className="form-wrapper">{renderBookedJobPositions(props.job)}</div>
  );
};
JobRow.propTypes = {
  job: PropTypes.any,
};

export default JobRow;
