import React from "react";
import PropTypes from "prop-types";
import EventTable from "components/ReactTable/LegacyReactTable";
import moment from "moment";

const JobRow = (props) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "LOCATION",
        accessor: "location",
      },
      {
        Header: "DATE",
        accessor: "date",
      },
      {
        Header: "EMPLOYEE",
        accessor: "employee",
      },
      {
        Header: "START",
        accessor: "start",
      },
      {
        Header: "ACTUAL START",
        accessor: "actualstart",
      },
      {
        Header: "END",
        accessor: "end",
      },
      {
        Header: "ACTUAL END",
        accessor: "actualend",
      },
      {
        Header: "OUTSTANDING AMOUNT",
        accessor: "outstandingamount",
      },

      {
        Header: "STATUS",
        accessor: "status",
      },
    ],
    []
  );

  const renderBookedJobPositions = (job) => {
    const filteredjobPositions = [];
    job.jobPositions.map((jobPosition, key) => {
      if (jobPosition?.booked && jobPosition?.booked?.employee) {
        filteredjobPositions?.push({
          location: jobPosition.jobPlace,
          date: moment(jobPosition.jobDate).format("dddd, DD.MM.YY") ?? "",
          employee: jobPosition.booked.employee.name,
          start: moment(jobPosition.jobStart).format("HH:mm") ?? "",
          actualstart: jobPosition.confirmJobStart
            ? moment(jobPosition.confirmJobStart).format("HH:mm")
            : "",
          end: moment(jobPosition.jobEnd).format("HH:mm") ?? "",
          actualend: jobPosition.confirmJobEnd
            ? moment(jobPosition.confirmJobEnd).format("HH:mm")
            : "",
          outstandingamount:
            "EUR " +
            Number(jobPosition?.employerData?.outstandingAmount ?? 0).toFixed(
              2
            ),
          status: (
            <span
              className={`${
                jobPosition?.employerData?.paidOutstandingAmount == true
                  ? "eventStatusPaid"
                  : "eventStatusOpen"
              }`}
            >
              <b>{`${
                jobPosition?.employerData?.paidOutstandingAmount == true
                  ? "PAID"
                  : "OPEN"
              }`}</b>
            </span>
          ),
        });
      }
      return jobPosition;
    });

    if (filteredjobPositions?.length > 0) {
      return (
        <>
          <h3 className="headline-with-line">{props.job.jobName}</h3>
          <EventTable
            columns={columns}
            data={filteredjobPositions}
            showPagination={false}
          />
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <div className="form-wrapper">{renderBookedJobPositions(props.job)}</div>
  );
};
JobRow.propTypes = {
  job: PropTypes.any,
};

export default JobRow;
