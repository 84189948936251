import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Assignment from '@material-ui/icons/Assignment';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import ReactTable from 'components/ReactTable/ReactTable';
import moment from 'moment';
import { getTimeReports } from 'actions/bookingAction';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const useStyles = makeStyles(styles);

export const convertToIntHour = (time) => {
  if (!time) {
    return 0;
  }
  const t = time
    .replace(':15', '.25')
    .replace(':30', '.5')
    .replace(':45', '.75');
  return parseFloat(t);
};

export const getJobHours = (endTime, startTime) => {
  if (startTime > endTime) {
    return endTime + 24 - startTime;
  } else {
    return endTime - startTime;
  }
};

const TimeReports = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [numberOfRows, setNumberOfRows] = React.useState(20);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [sortBy, setSortBy] = React.useState([]);
  const [filters, setFilters] = React.useState([]);

  const jobPositions = useSelector((state) => state.bookings.jobPositions);
  const totalPages = useSelector((state) => state.bookings.totalPages);
  const loading = useSelector((state) => state.bookings.loading);

  const onFetchData = (page, size, sortBy, filters) => {
    dispatch(getTimeReports(page, size, sortBy, filters));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'jobDate',
        sortType: (a, b) => {
          const aDate = moment(a.original.jobDate, 'DD.MM.YYYY').valueOf();
          const bDate = moment(b.original.jobDate, 'DD.MM.YYYY').valueOf();
          return aDate - bDate;
        },
      },
      {
        Header: 'Event ID',
        accessor: 'eventID',
        disableSortBy: false,
      },
      {
        Header: 'Event Name',
        accessor: 'eventName',
      },
      {
        Header: 'Job Position',
        accessor: 'jobPosition',
      },
      {
        Header: 'Employee',
        accessor: 'employeeName',
      },
      {
        Header: 'Hours Booked',
        accessor: 'hourBooked',
        Cell: ({ value }) => value.replace(':', 'h') + 'm',
      },
      {
        Header: 'Start',
        accessor: 'jobStartTime',
      },
      {
        Header: 'End',
        accessor: 'jobEndTime',
      },
      {
        Header: 'Break',
        accessor: 'breakTime',
      },
      {
        Header: 'Approved By',
        accessor: 'employerName',
        Cell: ({ value }) => value || '',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (!jobPositions || jobPositions.length === 0) return [];

    return jobPositions.map((jobPosition) => {
      const jobStartTime = moment(jobPosition.jobStartTime, 'HH:mm');
      const jobEndTime = moment(jobPosition.jobEndTime, 'HH:mm');
      const duration = moment.duration(jobEndTime.diff(jobStartTime));
      const totalWorkedHours = duration.asHours();
      const breakTime = totalWorkedHours > 6 ? '30 min' : '';

      const url = `/admin/timereports/${jobPosition.eventId}/${jobPosition.jobId}/${jobPosition.positionId}`;
      const actions = (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            history.push(url);
          }}
        >
          <Button
            round
            color="info"
            className={`${classes.actionButton} ${classes.actionButtonRound}`}
          >
            <ArrowForwardIcon className={classes.icon} />
          </Button>
        </a>
      );

      return {
        ...jobPosition,
        breakTime,
        actions,
      };
    });
  }, [jobPositions, classes, history]);

  const renderTable = useMemo(
    () => (
      <ReactTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        numberOfRows={numberOfRows}
        setNumberOfRows={setNumberOfRows}
        initSortBy={sortBy}
        setSortBy={setSortBy}
        initFilters={filters}
        setFilters={setFilters}
        columns={columns}
        data={data}
        onFetchData={onFetchData}
        totalPages={totalPages}
        loading={loading}
        manualPagination
        manualSortBy
        manualFilters
      />
    ),
    [
      pageIndex,
      numberOfRows,
      sortBy,
      filters,
      columns,
      data,
      totalPages,
      loading,
    ]
  );

  useEffect(() => {
    if (jobPositions && jobPositions.length) return;
    dispatch(getTimeReports());
  }, [dispatch, jobPositions]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Time Reports</h4>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '35px 15px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              renderTable
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default TimeReports;
