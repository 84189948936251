import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import SweetAlert from 'react-bootstrap-sweetalert';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Assignment from '@material-ui/icons/Assignment';
import Info from '@material-ui/icons/Info';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from 'components/Table/Table.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import EventTable from 'components/ReactTable/ReactTable';
import {
  getEvents,
  deleteEvent,
  getAllevents,
  // deleteTestimonial,
} from '../../actions/eventAction';
import { convertToDateFromMS } from '../../variables/time';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import alertStyles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { handleError } from '../../variables/handleError';
import moment from 'moment';
import propTypes from 'prop-types';

const useStyles = makeStyles({ ...styles, ...alertStyles });

const Event = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  const [numberOfRows, setNumberOfRows] = React.useState(20);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [sortBy, setSortBy] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [classicModal, setClassicModal] = React.useState({
    isVisible: false,
    type: 'create',
  });
  const [eventLoading, setEventLoading] = React.useState(false);
  const eventsData = useSelector((state) => state.events);
  const totalPages = useSelector((state) => state.events.totalPages);

  const onFetchData = async (page, size, sortBy, filters) => {
    dispatch(getEvents(page, size, sortBy, filters)).then(() => {});
  };

  const findEventIdsWithApplicants = (eventsData) => {
    const eventIds = new Set();

    eventsData?.data?.forEach((event) => {
      event.jobs?.forEach((job) => {
        job.jobPositions?.forEach((jobPosition) => {
          if (
            (jobPosition.applications && jobPosition.applications.length > 0) ||
            (jobPosition.rejectedApplicants &&
              jobPosition.rejectedApplicants.length > 0)
          ) {
            eventIds.add(event.eventID);
          }
        });
      });
    });
    return Array.from(eventIds);
  };

  const eventIdsWithApplicants = React.useMemo(
    () => findEventIdsWithApplicants(eventsData),
    [eventsData]
  );

  const classes = useStyles();

  const Cell = ({ value }) => (
    <div
      className={
        eventIdsWithApplicants.includes(value) ? 'cell-with-applicants' : ''
      }
    >
      {value}
    </div>
  );

  Cell.propTypes = {
    value: propTypes.any.isRequired,
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'eventID',
        Cell,
      },
      {
        Header: 'Company',
        accessor: 'companyName',
      },
      {
        Header: 'Name',
        accessor: 'eventName',
      },
      {
        Header: 'Expiration Date',
        accessor: 'eventExpirationDate',
        sortType: (a, b, columnId) => {
          var a1 = moment(a?.values?.eventExpirationDate, 'DD-MM-YYYY').format(
            'x'
          );
          var b1 = moment(b?.values?.eventExpirationDate, 'DD-MM-YYYY').format(
            'x'
          );
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: 'From',
        accessor: 'eventStart',
        sortType: (a, b, columnId) => {
          var a1 = moment(a?.values?.eventStart, 'DD-MM-YYYY').format('x');
          var b1 = moment(b?.values?.eventStart, 'DD-MM-YYYY').format('x');
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: 'To',
        accessor: 'eventEnd',
        sortType: (a, b, columnId) => {
          var a1 = moment(a?.values?.eventEnd, 'DD-MM-YYYY').format('x');
          var b1 = moment(b?.values?.eventEnd, 'DD-MM-YYYY').format('x');
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
      },
    ],
    [eventIdsWithApplicants]
  );
  const successDelete = async (eventId) => {
    let isMounted = true;
    setEventLoading(true);
    try {
      dispatch(
        deleteEvent({
          id: eventId,
          callback: () => {
            setEventLoading(false);
            setAlert(
              <SweetAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + ' ' + classes.success}
              >
                Event has been deleted.
              </SweetAlert>
            );
          },
        })
      );
    } catch (e) {
      setEventLoading(false);
      handleError(e);
    }
    // Clean-up:
    return () => {
      isMounted = false;
    };
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const warningWithConfirmMessage = (eventId) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => successDelete(eventId)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        Are you sure you want to delete this event ?
      </SweetAlert>
    );
  };

  const roundButtons = ({ data }) => {
    let btnArr;
    btnArr = [
      { type: 'edit', color: 'success', icon: Edit },
      { type: 'delete', color: 'danger', icon: Delete },
    ];

    return btnArr.map((prop, key) => {
      if (prop.type === 'edit') {
        return (
          <a
            href={`/admin/events/${data._id}`}
            target="_blank"
            rel="noopener noreferrer"
            key={key}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/admin/events/${data._id}`);
            }}
          >
            <Button
              round
              color={prop.color}
              className={classes.actionButton + ' ' + classes.actionButtonRound}
            >
              <prop.icon className={classes.icon} />
            </Button>
          </a>
        );
      } else {
        return (
          <Button
            round
            color={prop.color}
            className={classes.actionButton + ' ' + classes.actionButtonRound}
            key={key}
            onClick={async () => {
              if (prop.type === 'delete') {
                warningWithConfirmMessage(data._id);
              }
            }}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      }
    });
  };

  const renderTable = React.useMemo(() => {
    const events = eventsData.data;
    const filteredEvents = events.map((event, id) => {
      let jobStartDates = [];
      let jobEndDates = [];
      event.jobs.map((job) => {
        job.jobPositions.map((jobPosition, key) => {
          jobStartDates = [...jobStartDates, jobPosition.jobStart];
          jobEndDates = [...jobEndDates, jobPosition.jobEnd];
        });
      });
      const eventEndDate = jobEndDates.reduce(
        (previousValue, currentValue) =>
          previousValue < currentValue ? currentValue : previousValue,
        jobEndDates[0]
      );
      const eventStartdate = jobStartDates.reduce(
        (previousValue, currentValue) =>
          previousValue > currentValue ? currentValue : previousValue,
        jobStartDates[0]
      );

      return {
        ...event,
        id: id + 1,
        companyName: event?.jobs?.[0]?.companyName || '',
        eventExpirationDate: moment(
          convertToDateFromMS(event.eventExpirationDate)
        ).format('DD-MM-YYYY'),
        eventStart: eventStartdate
          ? moment(convertToDateFromMS(eventStartdate)).format('DD-MM-YYYY')
          : '',
        eventEnd: eventEndDate
          ? moment(convertToDateFromMS(eventEndDate)).format('DD-MM-YYYY')
          : '',
        actions: roundButtons({
          data: event,
        }),
      };
    });

    return (
      <EventTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        numberOfRows={numberOfRows}
        setNumberOfRows={setNumberOfRows}
        initSortBy={sortBy}
        setSortBy={setSortBy}
        initFilters={filters}
        setFilters={setFilters}
        columns={columns}
        data={filteredEvents}
        onFetchData={onFetchData}
        totalPages={totalPages}
      />
    );
  }, [pageIndex, numberOfRows, eventsData]);

  useEffect(() => {
    // if (eventsData && eventsData.data && eventsData.data.length) return;
    dispatch(getEvents());
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Events</h4>
          </CardHeader>
          <CardBody>
            {eventsData.loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '35px 15px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {eventLoading && (
                  <div className="loader absolute-loader">
                    <CircularProgress />
                  </div>
                )}
                {renderTable}
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Event;
